<template>
  <v-card class="mt-4" :to="dayTo" outlined>
    <v-card-title>{{ day.title }} </v-card-title>
    <v-card-subtitle> {{ day.ground }}, {{ formattedDate }} </v-card-subtitle>
    <v-card-text>
      <div class="text-overline">Responsible</div>
      <v-chip>
        <v-avatar left> <v-img :src="day.responsible.googlePicture"></v-img> </v-avatar>
        {{ day.responsible.fullname }}
      </v-chip>
      <div class="text-overline">Number of Runs</div>
      <div class="text-body-01">{{ day._count.testingRuns }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'TestingDayInfoOverviewCard',
  props: { day: { type: Object, required: true } },
  computed: {
    dayTo() {
      return { name: 'TestingDay', params: { date: this.day.date.substr(0, 10) } }
    },
    formattedDate() {
      return DateTime.fromISO(this.day.date).toFormat('dd LLL yyyy')
    }
  }
}
</script>
