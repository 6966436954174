<template>
  <v-card class="mt-4" :to="dayTo" outlined>
    <v-row>
      <v-col>
        <v-card-title>{{ setup.title }} </v-card-title>
        <v-card-subtitle> {{ formattedDate }} </v-card-subtitle>
      </v-col>
      <v-col>
        <v-card-text>
          <div class="text-overline">Setup by</div>
          <v-chip v-for="res in setup.responsibles" :key="res.responsibleId">
            <v-avatar left> <v-img :src="res.responsible.googlePicture"></v-img> </v-avatar>
            {{ res.responsible.fullname }}
          </v-chip>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text>
          <div class="text-overline">Car</div>
          <div class="text-body-01">{{ setup.car.name }} {{ setup.car.season }}</div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'SetupInfoOverviewCard',
  props: { setup: { type: Object, required: true } },
  computed: {
    dayTo() {
      return { name: 'Setup', params: { id: this.setup.id } }
    },
    formattedDate() {
      return DateTime.fromISO(this.setup.createdAt).toFormat('dd LLL yyyy')
    }
  }
}
</script>
