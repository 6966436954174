<template>
  <v-container>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab>Testing Runs</v-tab>
      <v-tab>Testing Days</v-tab>
      <v-tab>Base Setups</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat color="transparent">
          <v-card-text>
            <v-text-field
              append-icon="mdi-search-web"
              v-model="searchInput"
              label="Search for a specific testing run"
              outlined
            ></v-text-field>
            <v-pagination v-model="runPage" :length="runMaxPages"></v-pagination>
            <testing-run-info-overview-card v-for="run in runs" :key="run.id" :run="run" :showDay="true" />
            <div class="text-center">
              <v-pagination v-model="runPage" :length="runMaxPages"></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <testing-day-info-overview-card v-for="day in days" :key="day.date" :day="day" />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <setup-info-overview-card v-for="setup in setups" :key="setup.id" :setup="setup" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import TestingRunInfoOverviewCard from '@components/testingRuns/InfoOverviewCard.vue'
import TestingDayInfoOverviewCard from '@components/testingDays/InfoOverviewCard.vue'
import SetupInfoOverviewCard from '@components/setup/InfoOverviewCard.vue'

import { IdTestingRuns, SearchTestingRuns } from '@graphql/TestingRuns.gql'
import { DataTestingDays } from '@graphql/TestingDays.gql'
import { DataBaseSetups } from '@graphql/Setups.gql'

export default {
  name: 'Data',
  components: { TestingRunInfoOverviewCard, TestingDayInfoOverviewCard, SetupInfoOverviewCard },
  apollo: {
    runs: {
      // https://www.prisma.io/docs/concepts/components/prisma-client/full-text-search#mysql
      query: SearchTestingRuns,
      variables() {
        return {
          search_str: '*' + this.searchInput + '*',
          take: this.runTake,
          skip: this.runSkip,
          orderBy: [{ day: { date: 'desc' } }, { number: 'asc' }]
        }
      },
      update(data) {
        console.log(data)
        return data.searchTestingRuns
      },
      debounce: 700 // ms
    },
    allRuns: {
      query: IdTestingRuns,
      variables() {
        return {
          search_str: '*' + this.searchInput + '*'
        }
      },
      update(data) {
        console.log(data)
        return data.searchTestingRuns.length
      }
    },
    days: {
      query: DataTestingDays,
      update: (data) => data.testingDays
    },
    setups: {
      query: DataBaseSetups,
      update: (data) => data.baseSetups
    }
  },
  data: () => ({
    tab: null,
    runPage: 1,
    runTake: 10,
    runs: [],
    days: [],
    setups: [],
    searchInput: '',
    force_renderer_div: 0
  }),
  computed: {
    runSkip() {
      return (this.runPage - 1) * this.runTake
    },
    runMaxPages() {
      let pages = this.allRuns / this.runTake || 1
      if (pages - Math.floor(pages) !== 0) pages = Math.floor(pages) + 1
      return pages
    }
  }
}
</script>
